import { initMetrics } from 'metrics'

import {
  defineProject,
  initOverlayScrollbars,
  initPopups,
  initScrollToSection,
  OverlayScrollbarsContainer,
} from 'utils'

import { hideLinkAfterRegistration, initRegistrationWidget } from 'widgets'

initMetrics()

initPopups()

initScrollToSection()

initRegistrationWidget().then(() => {
  hideLinkAfterRegistration()
  setTimeout(() => {
    const el = document.querySelector('input[name=notASearchField]')
    if (!el) return
    ;(el as HTMLInputElement).value = ''
  }, 500)
})

defineProject()

const scrollBarsContainers: OverlayScrollbarsContainer[] = [
  { selector: 'body', theme: 'os-theme-light' },
  { selector: '.popup__text' },
]

initOverlayScrollbars(scrollBarsContainers)
